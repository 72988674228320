import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import type React from "react";

import { ListLoadingState } from "../../components/ListLoadingState";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { convertShiftToSlotId } from "../../Shift/Open/useGetShiftsSlotV2";
import { useOpenShiftListDataContext } from "../../Shift/Open/useOpenShiftListDataContext";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { ShiftVirtualizedList } from "../../Shift/Open/VirtualizedList";
import { usePreFetchGeolocation } from "../usePreFetchGeolocation";
import { ListViewPageOpenShiftsEmptyState } from "./ListEmptyState";

interface ListViewPageOpenShiftsListProps {
  daysCount: number;
  scrollRef: React.RefObject<HTMLDivElement>;
}

/**
 * Shows the list of open shifts and worker shifts for the current date
 * We prioritize showing open shifts and don't wait for worker shifts to load since:
 * 1. Worker shifts typically load faster than open shifts
 * 2. Open shifts are the primary content users want to see
 * Therefore we don't show loading/error states for worker shifts
 */
export function ListViewPageOpenShiftsList(props: ListViewPageOpenShiftsListProps) {
  const { daysCount, scrollRef } = props;
  const { listRef, virtualShiftListItems } = useVirtualShiftListContext();

  usePreFetchGeolocation();

  const isListEmpty = virtualShiftListItems.length === 0;

  const {
    onClickOpenShift,
    workplacesMap,
    shiftsMissingRequirementsMap,
    shiftsSlotsMap,
    isOpenShiftsLoading,
    isOpenShiftsError,
    isOpenShiftsSuccess,
    canLoadMore,
    loadMore,
    isLoadingMore,
    friendsMap,
    recentColleaguesMap,
    handleRangeChanged,
  } = useOpenShiftListDataContext();

  if (isOpenShiftsError) {
    return <Text>Error loading shifts</Text>;
  }

  if (isOpenShiftsLoading) {
    return (
      <ListLoadingState
        texts={[
          "Finding you the best-paying shifts nearby",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts nearby",
          "We are on it-finding shifts that matter most",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isOpenShiftsSuccess && isListEmpty) {
    return <ListViewPageOpenShiftsEmptyState daysCount={daysCount} />;
  }

  return (
    <ShiftVirtualizedList
      ref={listRef}
      scrollRef={scrollRef}
      items={virtualShiftListItems}
      isLoadingMore={isLoadingMore}
      renderItem={(item) => {
        const shift = item;

        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, showMissingDocumentsCount, isInstantReview } =
          shiftsMissingRequirementsMap.get(shift.id) ?? {};

        return (
          <OpenShiftCard
            key={shift.id}
            shift={shift}
            workplace={workplace}
            missingDocuments={missingDocuments ?? []}
            showMissingDocumentsCount={showMissingDocumentsCount ?? false}
            isInstantReview={isInstantReview ?? false}
            workers={
              shiftsSlotsMap
                .get(
                  convertShiftToSlotId({
                    start: shift.attributes.start,
                    end: shift.attributes.end,
                    facilityId: workplace.id,
                  })
                )
                ?.shiftAssignments.map(({ worker }) => worker) ?? []
            }
            friendsMap={friendsMap}
            recentColleaguesMap={recentColleaguesMap}
            onClick={({ offer }) => {
              onClickOpenShift({ shift, workplace, offer });
            }}
          />
        );
      }}
      onRangeChanged={handleRangeChanged}
      onEndReached={() => {
        if (canLoadMore) {
          loadMore();
        }
      }}
    />
  );
}
